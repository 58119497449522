
const storen = [
  {
    "id": "lamellenstoren",
    "title_de": "Lamellenstoren",
    "title_en": "Venetian Blinds",
    "title_it": "Veneziane",
    "description_de": "Lamellenstoren sind eine vielseitige und beliebte Fensterabdeckungslösung, die sowohl Funktionalität als auch Ästhetik bietet. Diese Jalousien bestehen aus horizontalen Lamellen aus verschiedenen Materialien wie Aluminium, Holz oder PVC, die angepasst werden können, um die Lichtmenge und Privatsphäre in einem Raum zu steuern. Eines der Hauptmerkmale von Jalousien sind ihre verstellbaren Lamellen, mit denen Sie die Menge des in Ihren Raum einfallenden Sonnenlichts ganz einfach regulieren können. Durch einfaches Neigen der Lamellen können Sie die perfekte Balance zwischen natürlichem Licht und Privatsphäre schaffen.",
    "description_en": "Venetian blinds are a versatile and popular window covering solution that offers both functionality and aesthetic appeal. These blinds consist of horizontal slats made of various materials such as aluminum, wood, or PVC, which can be adjusted to control the amount of light and privacy in a room. One of the key features of Venetian blinds is their adjustable slats, allowing you to easily regulate the amount of sunlight entering your space.By simply tilting the slats, you can create the perfect balance between natural light and privacy.",
    "description_it": "Le tende veneziane sono una soluzione di copertura per finestre versatile e popolare che offre sia funzionalità che estetica. Queste tende sono costituite da lamelle orizzontali realizzate in vari materiali come alluminio, legno o PVC, che possono essere regolate per controllare la quantità di luce e la privacy in una stanza. Una delle caratteristiche principali delle tende veneziane sono le lamelle regolabili, che ti consentono di regolare facilmente la quantità di luce solare che entra nel tuo spazio. Inclinando semplicemente le lamelle, puoi creare il perfetto equilibrio tra luce naturale e privacy.",
    image: require('../assets/images/lamellenstore.jpg')
  },
  {
    "id": "sonnenstoren",
    "title_de": "Sonnenstoren",
    "title_en": "Sun Blinds",
    "title_it": "Tende da sole",
    "description_de": "Sonnestoren sind eine beliebte Lösung zur Kontrolle von Sonnenlicht und Wärme sowohl in Wohn- als auch in Gewerberäumen. Diese vielseitigen Fensterabdeckungen bieten eine perfekte Balance zwischen Funktionalität und Stil. Sonnenschutzrollos sind so konzipiert, dass sie schädliche UV-Strahlen filtern, Blendung reduzieren und Privatsphäre bieten und gleichzeitig eine klare Sicht nach draußen gewährleisten. Sie sind in einer Vielzahl von Materialien erhältlich, darunter hochwertige Stoffe und innovative Sonnenschutzschirme, die die Hitze effektiv abhalten, ohne die Sicht zu beeinträchtigen. Mit ihrem schlanken und modernen Erscheinungsbild verleihen Sonnenschutzrollos jedem Raum oder Gebäude einen Hauch von Eleganz. Ganz gleich, ob Sie einen komfortablen Wohnraum schaffen oder das Arbeitsumfeld verbessern möchten, Sonnenschutzrollos sind eine ausgezeichnete Wahl, um das Licht zu steuern und eine angenehme Atmosphäre zu schaffen.",
    "description_en": "Sun blinds are a popular solution for controlling sunlight and heat in both residential and commercial spaces. These versatile window coverings offer a perfect balance between functionality and style. Sun blinds are designed to filter harmful UV rays, reduce glare, and provide privacy while maintaining a clear view of the outside. They come in a variety of materials, including high-quality fabrics and innovative solar screens that effectively block out heat without compromising visibility. With their sleek and modern appearance, sun blinds add a touch of elegance to any room or building. Whether you want to create a comfortable living space or enhance the working environment, sun blinds are an excellent choice for controlling light and maintaining a pleasant atmosphere.",
    "description_it": "Le tende da sole sono una soluzione popolare per il controllo della luce solare e del calore sia negli spazi residenziali che commerciali. Questi versatili rivestimenti per finestre offrono un perfetto equilibrio tra funzionalità e stile. Le tendine parasole sono progettate per filtrare i dannosi raggi UV, ridurre l'abbagliamento e fornire privacy mantenendo una visione chiara dell'esterno. Sono disponibili in una varietà di materiali, inclusi tessuti di alta qualità e schermi solari innovativi che bloccano efficacemente il calore senza compromettere la visibilità. Con il loro aspetto elegante e moderno, le tende da sole aggiungono un tocco di eleganza a qualsiasi stanza o edificio. Sia che tu voglia creare uno spazio abitativo confortevole o migliorare l'ambiente di lavoro, le tende da sole sono una scelta eccellente per controllare la luce e mantenere un'atmosfera piacevole.",
    image: require('../assets/images/sonnenstoren.jpeg')
  },
  {
    "id": "rollladen",
    "title_de": "Rollladen",
    "title_en": "Roller shutters",
    "title_it": "Tapparelle ",
    "description_de": "Rollläden sind eine vielseitige und praktische Lösung sowohl für Wohn- als auch für Gewerberäume. Diese Fensterabdeckungen bieten eine Reihe von Vorteilen und vereinen Funktionalität mit Ästhetik. Sie dienen auch als wirksame Barriere gegen raue Wetterbedingungen wie starken Wind, starken Regen und übermäßige Hitze. Darüber hinaus tragen Rollläden zur Energieeffizienz bei, indem sie Fenster isolieren und die Wärmeübertragung verringern. Dank ihrer reibungslosen und komfortablen Bedienung können diese Rollläden einfach angepasst werden, um die Lichtmenge und Belüftung in einem Raum zu steuern. Erhältlich in verschiedenen Materialien, Farben und Stilen.",
    "description_en": "Roller shutters are a versatile and practical solution for both residential and commercial spaces. These window coverings offer a range of benefits, combining functionality with aesthetics. They also serve as an effective barrier against harsh weather conditions, such as strong winds, heavy rain, and excessive heat. Additionally, roller shutters contribute to energy efficiency by insulating windows and reducing heat transfer. With their smooth and convenient operation, these shutters can be easily adjusted to control the amount of light and ventilation in a room. Available in various materials, colors, and styles.",
    "description_it": "Le tapparelle sono una soluzione versatile e pratica sia per gli spazi residenziali che commerciali. Questi rivestimenti per finestre offrono una serie di vantaggi, combinando funzionalità ed estetica. Servono anche come barriera efficace contro condizioni meteorologiche avverse, come forti venti, forti piogge e caldo eccessivo. Inoltre, le tapparelle contribuiscono all'efficienza energetica isolando le finestre e riducendo il trasferimento di calore. Con il loro funzionamento fluido e conveniente, queste persiane possono essere facilmente regolate per controllare la quantità di luce e ventilazione in una stanza. Disponibile in vari materiali, colori e stili.",
    image: require('../assets/images/rolllanden3.jpeg')
  },
  {
    "id": "sonnenstorestoffe",
    "title_de": "Sonnenstorenstoffe",
    "title_en": "Sun blinds fabrics",
    "title_it": "Tessuti per tende da sole ",
    "description_de": "Unsere Stoffe werden mit Präzision gefertigt und nutzen fortschrittliche Technologien, um eine optimale Wärme- und Lichtkontrolle zu gewährleisten. Sie filtern effektiv das Sonnenlicht, reduzieren die Blendung und verhindern, dass schädliche UV-Strahlen in den Raum gelangen. Sonnenschutzstoffe gibt es in einer großen Auswahl an Farben, Mustern und Texturen, sodass Sie ein individuelles und stilvolles Ambiente schaffen können. Dank ihrer langlebigen Konstruktion und einfachen Wartung bieten diese Stoffe eine langlebige Leistung. Ob für Wohn- oder Gewerbeanwendungen, Sonnenschutzstoffe bieten eine ideale Lösung für die Schaffung einer komfortablen und einladenden Umgebung bei gleichzeitiger Wahrung der Energieeffizienz.",
    "description_en": "Our fabrics are crafted with precision, incorporating advanced technologies to provide optimal heat and light control. They effectively filter sunlight, reducing glare and preventing harmful UV rays from entering the room. Sun blinds fabrics come in a wide range of colors, patterns, and textures, allowing you to create a personalized and stylish ambiance. With their durable construction and easy maintenance, these fabrics offer long-lasting performance. Whether for residential or commercial applications, sun blinds fabrics provide an ideal solution for creating a comfortable and inviting environment while preserving energy efficiency.",
    "description_it": "I nostri tessuti sono realizzati con precisione, incorporando tecnologie avanzate per fornire un controllo ottimale del calore e della luce. Filtrano efficacemente la luce solare, riducendo l'abbagliamento e impedendo ai dannosi raggi UV di entrare nella stanza. I tessuti delle tende da sole sono disponibili in un'ampia gamma di colori, motivi e trame, consentendo di creare un ambiente personalizzato ed elegante. Con la loro costruzione durevole e la facile manutenzione, questi tessuti offrono prestazioni di lunga durata. Che si tratti di applicazioni residenziali o commerciali, i tessuti per tende da sole forniscono una soluzione ideale per creare un ambiente confortevole e invitante preservando l'efficienza energetica.",
    image: require('../assets/images/stof.jpeg')
  },
  {
    "id": "insektenschutz",
    "title_de": "Insektenschutz",
    "title_en": "Insect Screen",
    "title_it": "Repellente per insetti",
    "description_de": "Der Insektenschutz ist eine hochwirksame Lösung, um lästige Insekten aus Ihren Wohnräumen fernzuhalten und gleichzeitig die freie Luftzirkulation zu ermöglichen. Dieser Fensterschutz besteht aus strapazierfähigem und feinmaschigem Material und fungiert als Barriere gegen Fliegen, Mücken und andere unerwünschte Insekten. Es ist so konzipiert, dass es genau über Ihre Fenster passt und einen zuverlässigen Schutz bietet, ohne die Sicht oder Belüftung zu beeinträchtigen. Durch die einfache Installation und Entfernung können Sie in den wärmeren Monaten insektenfreie Wohnbereiche genießen und gleichzeitig eine komfortable und atmungsaktive Umgebung bewahren. Verabschieden Sie sich von lästigen Insekten und genießen Sie ein friedliches und wanzenfreies Zuhause mit dem Insektenschutz.",
    "description_en": "The insect net window protector is a highly effective solution for keeping pesky insects out of your living spaces while allowing fresh air to circulate freely. Made from durable and fine mesh material, this window protector acts as a barrier against flies, mosquitoes, and other unwanted insects. It is designed to fit snugly over your windows, providing a reliable shield without compromising the view or ventilation. With its easy installation and removal process, you can enjoy insect-free living areas during warmer months while maintaining a comfortable and breathable environment. Say goodbye to bothersome bugs and embrace a peaceful and bug-free home with the insect net window protector.",
    "description_it": "La protezione per finestre con zanzariera è una soluzione altamente efficace per tenere gli insetti fastidiosi fuori dai tuoi spazi abitativi, consentendo all'aria fresca di circolare liberamente. Realizzata in materiale resistente e a maglia fine, questa protezione per finestre funge da barriera contro mosche, zanzare e altri insetti indesiderati. È progettato per adattarsi perfettamente alle finestre, fornendo uno scudo affidabile senza compromettere la vista o la ventilazione. Con il suo facile processo di installazione e rimozione, puoi goderti zone giorno prive di insetti durante i mesi più caldi, mantenendo un ambiente confortevole e traspirante. Dì addio a fastidiosi insetti e abbraccia una casa tranquilla e priva di insetti con la protezione per finestre con zanzariera.",
    image: require('../assets/images/insect.jpeg')
  },
  {
    "id": "stoff-rollo",
    "title_de": "Stoff-Rollo und Plisse",
    "title_en": "Fabric roller blind and pleated blind",
    "title_it": "Tenda a rullo in tessuto e tenda plissettata",
    "description_de": "Das Stoffrollo besteht aus einer Stoffbahn, die sich sauber auf einem Rollmechanismus aufrollen lässt, sodass Sie die Lichtmenge und Privatsphäre in Ihrem Raum ganz einfach anpassen können. Dank der großen Auswahl an Stoffoptionen können Sie das Rollo individuell an Ihre Inneneinrichtung und Ihren persönlichen Stil anpassen Das Plissee ist eine beliebte Wahl, um Ihren Fenstern einen Hauch von Eleganz zu verleihen. Es zeichnet sich durch ein einzigartiges Faltdesign aus, das bei voller Ausdehnung saubere und klare Falten erzeugt. Die Falten verbessern nicht nur die optische Attraktivität des Rollos, sondern sorgen auch für eine hervorragende Lichtkontrolle und Privatsphäre. Mit einer großen Auswahl an verfügbaren Stoffoptionen, darunter durchscheinende, undurchsichtige und gemusterte Materialien, können Sie in Ihrem Raum das gewünschte Maß an Privatsphäre und Lichtfilterung erreichen.",
    "description_en": "The fabric roller blind consists of a fabric panel that rolls up neatly onto a roller mechanism, allowing you to easily adjust the amount of light and privacy in your space. With a wide range of fabric options available, you can customize the roller blind to match your interior décor and personal style, meanwhile, the pleated blind is a popular choice for adding a touch of elegance to your windows. It features a unique folding design, creating neat and crisp pleats when fully extended. The pleats not only enhance the visual appeal of the blind but also provide excellent light control and privacy. With a wide range of fabric options available, including translucent, opaque, and patterned materials, you can achieve the desired level of privacy and light filtering in your space. ",
    "description_it": "La tenda a rullo in tessuto è costituita da un pannello in tessuto che si avvolge ordinatamente su un meccanismo a rullo, consentendoti di regolare facilmente la quantità di luce e privacy nel tuo spazio. Con un'ampia gamma di opzioni di tessuto disponibili, puoi personalizzare la tenda avvolgibile in base al tuo arredamento interno e al tuo stile personale la tenda plissettata è una scelta popolare per aggiungere un tocco di eleganza alle tue finestre. È dotato di un design pieghevole unico, che crea pieghe pulite e nitide quando è completamente esteso. Le pieghe non solo migliorano l'aspetto visivo della tenda, ma forniscono anche un eccellente controllo della luce e privacy. Con un'ampia gamma di opzioni di tessuto disponibili, inclusi materiali traslucidi, opachi e fantasia, puoi ottenere il livello desiderato di privacy e filtraggio della luce nel tuo spazio.",
    image: require('../assets/images/plissee.jpeg')
  }, 
  {
    "id": "rolllamellenstoren",
    "title_de": "Rolllamellenstoren",
    "title_en": "Roller Blinds",
    "title_it": "Tende a rullo",
    "description_de": "Rolllamellenstoren sind eine vielseitige und praktische Fensterabdeckungslösung, die Funktionalität mit Stil verbindet. Diese Jalousien bestehen aus einem einzigen Stück Stoff, das sich mithilfe eines Mechanismus auf- und abrollen lässt, sodass Sie die Lichtmenge und Privatsphäre in Ihrem Raum einfach steuern können. Rollos sind in einer großen Auswahl an Farben, Mustern und Materialien erhältlich, sodass Sie die perfekte Ergänzung zu Ihrem Einrichtungsthema finden. Sie bieten hervorragende lichtblockierende Eigenschaften und eignen sich daher ideal für Schlafzimmer oder Räume, in denen Sie die Blendung reduzieren möchten. Darüber hinaus sind Rollos leicht zu reinigen und zu warten, was sie zu einer bequemen Wahl für vielbeschäftigte Menschen macht. Ob Sie einen schlichten und minimalistischen Look oder ein mutiges und lebendiges Design bevorzugen, Rollos bieten eine praktische und ästhetisch ansprechende Lösung für jedes Fenster.",
    "description_en": "Roller blinds are a versatile and practical window covering solution that combines functionality with style. These blinds consist of a single piece of fabric that rolls up and down using a mechanism, allowing you to easily control the amount of light and privacy in your space. Roller blinds are available in a wide range of colors, patterns, and materials, allowing you to find the perfect match for your interior design theme. They provide excellent light-blocking capabilities, making them ideal for bedrooms or rooms where you want to reduce glare. Additionally, roller blinds are easy to clean and maintain, making them a convenient choice for busy individuals. Whether you prefer a sleek and minimalistic look or a bold and vibrant design, roller blinds offer a practical and aesthetically pleasing solution for any window.",
    "description_it": "Le tende a rullo sono una soluzione versatile e pratica per la copertura delle finestre che unisce funzionalità e stile. Queste tende sono costituite da un unico pezzo di tessuto che si arrotola e si abbassa utilizzando un meccanismo, che consente di controllare facilmente la quantità di luce e la privacy nel proprio spazio. Le tende a rullo sono disponibili in un'ampia gamma di colori, motivi e materiali, permettendoti di trovare l'abbinamento perfetto per il tuo tema di interior design. Forniscono eccellenti capacità di blocco della luce, rendendole ideali per camere da letto o stanze in cui si desidera ridurre l'abbagliamento. Inoltre, le tende a rullo sono facili da pulire e mantenere, rendendole una scelta conveniente per le persone impegnate. Sia che tu preferisca un look elegante e minimalista o un design audace e vivace, le tende a rullo offrono una soluzione pratica ed esteticamente gradevole per qualsiasi finestra.",
    image: require('../assets/images/rollstore.jpeg')
  },
  {
    "id": "seitenglass",
    "title_de": "Seitenglass",
    "title_en": "Side Glass",
    "title_it": "Vetro laterale",
    "description_de": "Seitenglas ist ein vielseitiges architektonisches Element, das jedem Raum Eleganz und Funktionalität verleiht. Damit sind Glasscheiben gemeint, die neben Türen, Fenstern oder anderen architektonischen Öffnungen angebracht sind. Seitenglas erfüllt mehrere Zwecke, darunter die Verbesserung des natürlichen Lichtflusses, die Bereitstellung von Sichtbarkeit und die Hinzufügung eines ästhetischen Reizes zum Gesamtdesign. Ob im Wohn- oder Gewerbebereich, Seitenglas bietet eine nahtlose Integration zwischen Innen- und Außenbereich und schafft ein Gefühl von Offenheit und Verbundenheit. Mit verschiedenen verfügbaren Optionen, darunter klares, mattiertes, getöntes oder strukturiertes Glas, kann das Seitenglas individuell angepasst werden, um spezifische Designvorlieben, Privatsphärenbedürfnisse und Energieeffizienzanforderungen zu erfüllen. Sein elegantes und modernes Erscheinungsbild in Kombination mit seinen praktischen Vorteilen macht Seitenglas zu einer beliebten Wahl für Architekten, Designer und Hausbesitzer, die die Funktionalität und Ästhetik ihrer Räume verbessern möchten.",
    "description_en": "Side glass is a versatile architectural element that adds elegance and functionality to any space. It refers to the glass panels installed alongside doors, windows, or other architectural openings. Side glass serves multiple purposes, including enhancing natural light flow, providing visibility, and adding aesthetic appeal to the overall design. Whether used in residential or commercial settings, side glass offers a seamless integration between the indoors and outdoors, creating a sense of openness and connectivity. With various options available, including clear, frosted, tinted, or textured glass, side glass can be customized to meet specific design preferences, privacy needs, and energy efficiency requirements. Its sleek and modern appearance, combined with its practical benefits, makes side glass a popular choice for architects, designers, and homeowners seeking to elevate the functionality and aesthetics of their spaces.",
    "description_it": "Il vetro laterale è un elemento architettonico versatile che aggiunge eleganza e funzionalità a qualsiasi spazio. Si riferisce ai pannelli di vetro installati accanto a porte, finestre o altre aperture architettoniche. Il vetro laterale ha molteplici scopi, tra cui migliorare il flusso di luce naturale, fornire visibilità e aggiungere fascino estetico al design generale. Utilizzato in ambienti residenziali o commerciali, il vetro laterale offre una perfetta integrazione tra interno ed esterno, creando un senso di apertura e connettività. Con varie opzioni disponibili, tra cui vetro trasparente, smerigliato, colorato o strutturato, il vetro laterale può essere personalizzato per soddisfare specifiche preferenze di design, esigenze di privacy e requisiti di efficienza energetica. Il suo aspetto elegante e moderno, combinato con i suoi vantaggi pratici, rende il vetro laterale una scelta popolare per architetti, designer e proprietari di case che cercano di elevare la funzionalità e l'estetica dei loro spazi.",
    image: require('../assets/images/seitenglass.jpg')
  },
  {
    "id": "lamellendach",
    "title_de": "Lamellendach",
    "title_en": "Louvered Roof",
    "title_it": "Tetto a lamelle",
    "description_de": "Dieses ausgeklügelte System besteht aus verstellbaren horizontalen Lamellen, die gedreht werden können, um die Menge an Sonnenlicht und die Belüftung zu steuern, die in den darunter liegenden Raum gelangt. Durch die Möglichkeit, die Lamellen zu neigen, können Sie die Menge an Schatten, Licht und Luftstrom ganz einfach regulieren, um eine angenehme Umgebung zu schaffen. Lamellendächer sind äußerst langlebig und witterungsbeständig und eignen sich daher für verschiedene Außenbereiche wie Innenhöfe, Terrassen oder Gartenbereiche. Sie bieten hervorragenden Schutz vor starker Sonneneinstrahlung, Regen und sogar leichtem Schnee. Darüber hinaus können Lamellendächer motorisiert oder manuell bedient werden, sodass Sie die Lamellen bequem nach Ihren Wünschen einstellen können. Ganz gleich, ob Sie ein Erlebnis unter freiem Himmel genießen oder Schutz vor den Elementen suchen möchten, ein Lamellendach bietet Flexibilität und steigert die Gesamtattraktivität Ihres Wohnbereichs im Freien.",
    "description_en": "This sophisticated system consists of adjustable horizontal louvers that can be rotated to control the amount of sunlight and ventilation entering the space beneath. With the ability to tilt the louvers, you can easily regulate the amount of shade, light, and airflow to create a comfortable environment. Louvered Roofs are highly durable and weather-resistant, making them suitable for various outdoor settings such as patios, terraces, or garden spaces. They provide excellent protection against harsh sun rays, rain, and even light snow. Additionally, Louvered Roofs can be motorized or operated manually, allowing you to conveniently adjust the louvers based on your preferences. Whether you want to enjoy an open-air experience or seek shelter from the elements, a Louvered Roof offers flexibility and enhances the overall appeal of your outdoor living area.",
    "description_it": "Questo sofisticato sistema è costituito da feritoie orizzontali regolabili che possono essere ruotate per controllare la quantità di luce solare e ventilazione che entrano nello spazio sottostante. Con la possibilità di inclinare le feritoie, puoi facilmente regolare la quantità di ombra, luce e flusso d'aria per creare un ambiente confortevole. I tetti a persiana sono altamente durevoli e resistenti agli agenti atmosferici, il che li rende adatti a vari ambienti esterni come cortili, terrazze o giardini. Forniscono un'eccellente protezione contro i raggi del sole, la pioggia e persino la neve leggera. Inoltre, i tetti a persiana possono essere motorizzati o azionati manualmente, consentendo di regolare comodamente le feritoie in base alle proprie preferenze. Sia che vogliate vivere un'esperienza all'aria aperta o cercare riparo dalle intemperie, un tetto a lamelle offre flessibilità e migliora l'attrattiva generale della vostra zona giorno all'aperto.",
    image: require('../assets/images/lamellendach.jpeg')
  },
  
]


export default storen