import React from 'react'


function Map() {
  return (
    <>
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.084166706563!2d8.2797923!3d47.35175470000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47901177ec802ad9%3A0x960b110f31755fae!2sBesi%20Storen%20GmbH!5e0!3m2!1sen!2smk!4v1684949792156!5m2!1sen!2smk" width="100%" height="450"  allowFullScreen="" title='map' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </>
  )
}

export default Map